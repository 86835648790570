<template>
  <AuthForm
    :title="isCustomerApp ? undefined : title"
    text="Vul uw informatie in om toegang te krijgen tot het portaal"
    :link="{label: 'Wachtwoord vergeten?', to: {name: 'auth-request-password'}}"
  >
    <SofieAuthLoginForm @error="onError" />
  </AuthForm>
</template>

<script lang="ts" setup>
definePageMeta({
  auth: {
    unauthenticatedOnly: true,
  },
  layout: 'auth',
});

const {appName, isCustomerApp} = useApp();

const title = computed(() => `Inloggen bij ${appName.value}`);

useSeoMeta({
  title,
});

function onError() {
  notify({
    type: 'error',
    text: 'Er is iets misgegaan, probeer het opnieuw.',
  });
}
</script>
